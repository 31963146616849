import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Banner from "../components/banner/banner-v2"
import Info from "../components/info/info"
import Gallery from "../components/gallery/gallery-v2"
import { graphql, useStaticQuery } from "gatsby"

const IndexPage = () => {
  const bannerImag = useStaticQuery(graphql`
    query {
      imgBanner: file(relativePath: { eq: "top-img.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1980) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO title="Home" />
      <Banner img={bannerImag} />
      <Info />
      <Gallery />
    </Layout>
  )
}

export default IndexPage
