import React from "react"

import DatePicker from "react-date-picker"

import "./banner.scss"
import BackgroundImage from "gatsby-background-image"
import Arrow from "../../images/icons/arrow.svg"

class Banner extends React.Component {
  constructor() {
    super()

    this.state = {
      date: new Date(),
      where: "Select",
      what: "Select",
    }

    this.onChange = date => this.setState({ date })
    this.toggleDatepickerControls = () => {}
  }

  setPlace(place) {
    this.setState({ where: place })
  }
  setType(type) {
    this.setState({ what: type })
  }

  search() {
    const { what, where, date } = this.state
    window.location = `/results-v2?what=${what}&where=${where}&date=${date}`
  }

  componentDidMount() {
    let selectBtn = document.getElementsByClassName("dropdown"),
      dropdownMenu = document.getElementsByClassName("dropdownMenu")

    for (let i = 0; i < selectBtn.length; i++) {
      selectBtn[i].onclick = function() {
        if (this.nextElementSibling.className.indexOf("active") > -1) {
          for (let j = 0; j < selectBtn.length; j++) {
            removeClass(selectBtn[j].nextElementSibling, "active")
          }
        } else {
          addClass(this.nextElementSibling, "active")
        }
      }
    }
    for (let i = 0; i < dropdownMenu.length; i++) {
      let child = dropdownMenu[i].children
      for (let j = 0; j < child.length; j++) {
        child[j].onclick = function() {
          // let text = this.innerHTML
          // this.parentNode.previousElementSibling.children[0].innerHTML = text
          toggleClass(this.parentNode, "showMenu")
        }
      }
    }

    window.addEventListener("click", function(event) {
      for (let i = 0; i < selectBtn.length; i++) {
        if (event.target !== selectBtn[i].children[0]) {
          removeClass(selectBtn[i].nextElementSibling, "active")
        }
      }
    })

    function toggleClass(el, classToToggle) {
      let classN = el.className
      if (classN.indexOf(classToToggle) > -1) {
        el.className = classN.replace(" " + classToToggle, "")
      } else {
        el.className = classN + " " + classToToggle
      }
    }

    function addClass(el, classToToggle) {
      let classN = el.className
      if (classN.indexOf(classToToggle) < 1) {
        el.className = classN + " " + classToToggle
      }
    }

    function removeClass(el, classToToggle) {
      let classN = el.className
      if (classN.indexOf(classToToggle) > -1) {
        el.className = classN.replace(" " + classToToggle, "")
      }
    }
  }

  render() {
    return (
      <div className="wrapper-banner">
        <BackgroundImage
          Tag="div"
          className={"bg-banner"}
          fluid={this.props.img.imgBanner.childImageSharp.fluid}
        />
        <div className="trvl">
          <p>TRVL</p>
        </div>
        <h1 className="main-title">Book your stay</h1>
        <div className="filter-container">
          <div className="block-dropdown">
            <div className="dropholder">
              <p className="title-select">Type</p>
              <div className="dropdown line">
                <p>{this.state.what}</p>
              </div>
              <ul className="dropdownMenu">
                <li onClick={() => this.setType("Caravan")}>Caravan</li>
                <li onClick={() => this.setType("Tiny House")}>Tiny House</li>
                <li onClick={() => this.setType("Treehouse")}>Treehouse</li>
                <li onClick={() => this.setType("Tent")}>Tent</li>
                <li onClick={() => this.setType("Backyard")}>Backyard</li>
              </ul>
              <div className="arrow">
                <Arrow />
              </div>
            </div>
          </div>

          <div className="block-dropdown">
            <div className="dropholder">
              <p className="title-select">where</p>
              <div className="dropdown line">
                <p>{this.state.where}</p>
              </div>
              <ul className="dropdownMenu">
                <li onClick={() => this.setPlace("Canada")}>Canada</li>
                <li onClick={() => this.setPlace("The Great Lakes")}>
                  The Great Lakes
                </li>
                <li onClick={() => this.setPlace("East Coast")}>East Coast</li>
                <li onClick={() => this.setPlace("West Coast")}>West Coast</li>
                <li onClick={() => this.setPlace("Down South")}>Down South</li>
                <li onClick={() => this.setPlace("Middle America")}>
                  Middle America
                </li>
                <li onClick={() => this.setPlace("Alaska")}>Alaska</li>
                <li onClick={() => this.setPlace("Hawaii")}>Hawaii</li>
              </ul>

              <div className="arrow">
                <Arrow />
              </div>
            </div>
          </div>

          <div className="block-dropdown">
            <div className="dropholder">
              <p className="title-select">when</p>
              <DatePicker
                onChange={this.onChange}
                value={this.state.date}
                clearIcon={null}
                dayPlaceholder="DD"
                dayAriaLabel="Day"
                monthPlaceholder="MM"
                monthAriaLabel="Month"
                yearPlaceholder="YYYY"
                yearAriaLabel="Year"
                format="MM/DD/YYYY"
                onCalendarOpen={() => {
                  console.log("")
                }}
                onCalendarClose={() => {
                  console.log("")
                }}
              />
            </div>
          </div>

          <a className="search-btn" onClick={() => this.search()}>
            Search
          </a>
        </div>
      </div>
    )
  }
}

export default Banner
